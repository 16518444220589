/* Global Reset */
* {
  box-sizing: border-box;
}

/* Small devices (landscape phones, 767px and down) */
@media (max-width: 767px) {
  /* Custom css */

  .display-1 {
    font-size: 2rem;
  }
  .display-6 {
    font-size: 1rem;
  }

  .header-text {
    width: 100%;
  }

  /* Home Page */ 
  .pp-header-img {
    width: 100%;
  }

  /* About Page */
  .panel {
    padding: 2rem 2rem;
  }

  /* Contact Page */
  .contact-form {
    width: 100%;
  }
  .call-panel {
    width: 100%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .display-1 {
    font-size: 2.75rem;
  }
  .display-6 {
    font-size: 1.25rem;
  }
  /* Home Page */
  .pp-header-img {
      width: 80%;
    }
  /* About Page */
  .panel {
    padding: 2rem 5rem;
  }

  /* Contact Page */
  .contact-form {
    width: 500px;
  }
  .call-panel {
    width: 500px;
  }
}

/* Large devices and down */
@media (max-width: 992px) {
  /* Home Page */
  


  /* NavBar */
  .nav-links {
    background: #d5192c;
    color: #fff;
    width: 100%;
    max-height: 0;
    position: absolute;
    flex-direction: column;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    text-align: center;
    transition: max-height ease 0.5s;
    z-index: 0;
  }
  .nav-links.toggled {
    max-height: 600px;
  }

  .sub-links {
    background: #f71e33;
    max-height: 0;
    overflow: hidden;
    transition: max-height ease 0.3s;
  }

  .sub-links.toggled {
    max-height: 150px;
    display: block;
  }
  .my-btn {
    color: #fff;
    background: #d5192c;
    border: none;
    transition: all ease 0.3s;
    border-radius: 5px;
  }
  .nav-links > a,
  .sub-links > a {
    padding: 1em 0;
    display: block;
  }

  .nav-links > a:hover,
  .sub-links > a:hover {
    color: #dcdcdc;
  }

  .sm-none {
    display: none !important;
  }
  .header-text {
    width: 100%;
  }
  .logo {
    width: 50px;
  }

  /* About Page */
  .pp-header-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  /* EV Charging Page */

  /* Contact Page */

  /* Custom css */
  .md-none {
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* Nav Bar */
  .nav-toggle {
    display: none;
  }
  .nav-links {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .nav-links > a {
    padding: 3px 5px;
    margin: 1em 0;
  }
  .sub-links {
    margin: 2em;
    color: #fff;
    padding: 1em;
    background-color: #d5192c;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 45%;
    right: 17%;
    transform: translateX(-50%);
  }

  .sub-links.hovered {
    display: flex;
  }
  .sub-links > a {
    padding: 0.5em 1em;
  }

  .sub-links > a:hover {
    color: #bfbfbf;
  }

  .my-btn {
    padding: 1em 2em;
    color: #fff;
    background: #d5192c;
    border: none;
    transition: all ease 0.3s;
    border-radius: 5px;
  }

  .my-btn:hover {
    background: #a91322;
  }

  /* Home Page */
  .display-1 {
    font-size: 3rem;
  }
  .display-6 {
    font-size: 1.75rem;
  }
  .logo {
    width: 75px;
  }
  .nav-logo-2 {
    width: 125px;
  }
  .header-text {
    width: 80%;
  }
  .lg-none {
    display: none;
  }
  /* About Page */
  .shape {
    clip-path: polygon(48% 0, 100% 0%, 75% 100%, 19% 100%);
    background-color: #d5192c;
    width: 200px;
    height: 350px;
  }
  .about-container {
    grid-template-columns: 1fr;
    gap: 1em;
  }
  .pp-header-img-container {
    display: none;
  }
  /* Contact Page */
  .contact-page {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  /* EV install page */
  .ev-main {
    width: 100%;
  }
  .trap-shape {
    width: 50%;
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
    background-color: #d5192c;
  }

  .ev-sec-1 {
    width: 100%;
    margin: 5em 0;
    position: relative;
  }

  .ev-sec-1 > .text {
    width: 50%;
  }

  .ev-sec-1 > img {
    position: absolute;
    height: 275px;
    border-radius: 5px;
    box-shadow: 0 0 10px #212529;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
  }

  /* Custom Css */
  .custom-hover {
    position: relative;
  }

  .custom-hover::after {
    position: absolute;
    left: 0;
    bottom: -2px;
    display: block;
    content: "";
    width: 0%;
    height: 3px;
    background-color: #c11f2f;
    transition: width ease 0.5s;
  }

  .custom-hover:hover::after {
    width: 100%;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Nav bar */
  .nav-links {
    width: 45%;
  }

  .sub-links {
    right: 12%;
  }
  .display-1 {
    font-size: 4rem;
  }
  .display-6 {
    font-size: 2rem;
  }
  .about-container {
    margin: 0 10rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .display-1 {
    font-size: 4.5rem;
  }
  .display-6 {
    font-size: 2.5rem;
  }
}

/* Navbar styling */
.nav {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  z-index: 10;
}

.nav-toggle {
  cursor: pointer;
  font-size: 30px;
}

/* Home page custom CSS */
.pp-color {
  color: #c11f2f;
}

.home-header {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.home-header-img {
  width: 33%;
  position: absolute;
  background: rgb(255, 255, 255, 1);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom: 1px solid #d5192c;
  border-left: 1px solid #d5192c;
  border-right: 1px solid #d5192c;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
  padding: 1em 0;
}

/* Nav Bar */
.nav-logo-2 {
  display: flex;
  flex-direction: column;
  width: 200px;
}

/* Home Page */
.img-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
}

.cred-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cred-img {
  width: 33%;
}

.service-list > li {
  list-style: square;
  color: #d5192c;
}

.service-list > li > p {
  color: #212529;
}

.pp-text {
  width: 40%;
}

/* About page CSS */
.about-container {
  display: grid;
  margin-top: 5rem;
  margin-bottom: 8rem;
}



/* Custom classes */
a {
  text-decoration: none;
  color: inherit;
}

.rm-all-pm {
  margin: 0 !important;
  padding: 0 !important;
}

.main-margin {
  margin-top: 5em;
}

.footer {
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  background-color: #fefefe;
}

.custom-border {
  border-top: 2px solid #c11f2f;
}

.tel-link:hover {
  text-decoration: underline;
  text-decoration-color: #c11f2f;
}

/* Contact Page */
.success {
  color: rgb(42, 167, 42);
}

.contact-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2em 3em;
  border-radius: 10px;
}

.contact-form > label {
  margin: 10px 0;
}

.contact-form > input {
  height: 2em;
  width: 100%;
}

.contact-form > input:focus,
.contact-form > textarea:focus {
  outline: none;
}

.form-btn {
  margin-top: 1em;
  padding: 1em 1.5em;
  border-radius: 3px;
  background-color: #d5192c;
  border: none;
  color: #fff;
  transition: all ease 0.3s;
}

.form-btn:hover {
  background-color: #a91322;
}

.contact-page > .call-panel {
  position: relative;
  color: #fff;
  background: #d5192c;
  height: 175px;
  border-radius: 10px;
  padding: 1.5em 3em;
  margin: 2em 0;
}

.contact-page > .call-panel > .call-btn {
  color: #fff;
  background-color: #d5192c;
  width: calc(100% - 6em);
  position: absolute;
  padding: 1em 0;
  left: 50%;
  bottom: 15%;
  transform: translateX(-50%);
  border-radius: 10px;
  border: 2px solid #fff;
  transition: all ease 0.3s;
  text-align: center;
}

.contact-page > .call-panel > .call-btn:hover {
  background: #a91322;
}

/* EV Charging Install Page */

.ev-sec-1 > .text {
  padding: 1em 2em;
}

.ev-sec-2 {
  margin: 10em 0;
  padding: 0 2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ev-sec-1 > div > p,
.ev-sec-2 > p {
  font-size: 20px;
}

.ev-sec-1 > div > ul,
.ev-sec-2 > ul {
  list-style: square;
  color: #c11f2f;
}

.ev-sec-1 > div > ul > li,
.ev-sec-2 > ul > li {
  font-size: 22px;
}

.ev-sec-1 > div > ul > li > p,
.ev-sec-2 > ul > li > p {
  color: #212529;
  font-size: 18px;
}

/* OLEV page */

.olev-services-list {
  list-style: square;
  color: #c11f2f;
}

.olev-services-list > li > p {
  color: #212529;
}
